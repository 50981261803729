<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-form class="list-view product-checkout mt-0" style="display: block;">
      <!-- Left Form -->
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <b-card-title>กรอกรายละเอียดลูกค้า</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Mobile Number"
                rules="required"
              >
                <b-form-group
                  label="เบอร์โทรศัพท์"
                  label-for="mobile-number"
                  :state="getValidationState(validationContext)"
                >
                  <!-- <v-select
                    v-model="addressDetails.selectedContactMobile"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsContactAddress"
                    input-id="full-name"
                    :clearable="false"
                    class="select-size-lg"
                    label="mobile"
                    @input="setContactAddress"
                    push-tags
                    taggable
                  /> -->

                  <b-form-input
                    id="txtContactMobile"
                    v-model="addressDetails.mobile"
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="เบอร์โทรศัพท์"
                    @input="setContactAddress"
                    v-limit-input-length="10"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Full Name -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Full Name"
                rules="required"
              >
                <b-form-group
                  label="ชื่อ - นามสกุล"
                  label-for="full-name"
                  :state="getValidationState(validationContext)"
                >
                  <!-- <v-select
                    v-limit-input-length="40"
                    v-model="addressDetails.selectedContact" 
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsFullname"
                    input-id="full-name"
                    :clearable="false"
                    class="select-size-lg"
                    label="label"
                    push-tags
                    taggable
                  /> -->

                  <b-form-input
                    id="full-name"
                    v-model="addressDetails.fullname"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="ชื่อ - นามสกุล"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="ที่อยู่จัดส่ง"
                rules="required"
              >
                <b-form-group
                  label="ที่อยู่จัดส่ง"
                  label-for="ที่อยู่จัดส่ง"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="addressDetail"
                    v-model="addressDetails.addressDetail"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="ที่อยู่จัดส่ง"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Mobile Number -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="ค้นหา แขวง/ตำบล เขต/อำเภอ จังหวัด รหัสไปรษณีย์"
              >
                <b-form-group
                  label="ค้นหา แขวง/ตำบล เขต/อำเภอ จังหวัด รหัสไปรษณีย์"
                  label-for="mobile-number"
                  :state="getValidationState(validationContext)"
                >
                  <!-- <v-select
                    v-model="addressDetails.selectedContactMobile"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsContactAddress"
                    input-id="full-name"
                    :clearable="false"
                    class="select-size-lg"
                    label="mobile"
                    @input="setContactAddress"
                    push-tags
                    taggable
                  /> -->

                  <vue-autosuggest
                    :suggestions="filteredOptions"
                    :limit="10"
                    :input-props="{
                      id: 'autosuggest__input',
                      class: 'form-control',
                    }"
                    @input="onInputChange"
                    :get-suggestion-value="getSuggestionValue"
                  >
                    <template slot-scope="{ suggestion }">
                      <span class="my-suggestion-item">{{
                        suggestion.item.name
                      }}</span>
                    </template>
                  </vue-autosuggest>
                  <!-- 
                  <b-form-input
                    id="txtContactMobile"
                    v-model="addressDetails.mobile"
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="เบอร์โทรศัพท์"
                    @input="setContactAddress"
                    v-limit-input-length="10"
                  /> -->

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Full Name -->
            <b-col cols="12" md="6">
              <!-- <validation-provider
                #default="validationContext"
                name="Full Name"
                rules="required"
              >
                <b-form-group
                  label="ชื่อ - นามสกุล"
                  label-for="full-name"
                  :state="getValidationState(validationContext)"
                >
                 

                  <b-form-input
                    id="full-name"
                    v-model="addressDetails.fullname"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="ชื่อ - นามสกุล"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider> -->
            </b-col>

            <!-- Flat House No -->
            <!-- <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Detail"
                rules="required"
              >
                <b-form-group
                  label="ที่อยู่จัดส่ง"
                  label-for="ที่อยู่จัดส่ง"
                  :state="getValidationState(validationContext)"
                >
                 
                  <b-form-input
                    id="addressDetail"
                    v-model="addressDetails.addressDetail"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="ที่อยู่จัดส่ง"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col> -->

            <!-- Mobile Number -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="ตำบล"
                rules="required"
              >
                <b-form-group
                  label="ตำบล"
                  label-for="address-type"
                  :state="getValidationState(validationContext)"
                >
                  <!-- <v-select
                    v-model="addressDetails.subDistrict"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsSubdistrict"
                    input-id="address-type"
                    :clearable="false"
                    class="select-size-lg"
                    label="label"
                    push-tags
                    taggable
                  /> -->

                  <b-form-input
                    id="subDistrict"
                    v-model="addressDetails.subDistrict.label"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="ตำบล"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Address Type -->

            <!-- Address Type -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="อำเภอ"
                rules="required"
              >
                <b-form-group
                  label="อำเภอ"
                  label-for="district-type"
                  :state="getValidationState(validationContext)"
                >
                  <!-- <v-select
                    v-model="addressDetails.district"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsDistrict"
                    input-id="district-type"
                    :clearable="false"
                    class="select-size-lg"
                    label="label"
                    push-tags
                    taggable
                  /> -->

                  <b-form-input
                    id="district"
                    v-model="addressDetails.district.label"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="อำเภอ"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="จังหวัด"
                rules="required"
              >
                <b-form-group
                  label="จังหวัด"
                  label-for="province-type"
                  :state="getValidationState(validationContext)"
                >
                  <!-- <v-select
                    v-model="addressDetails.province"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsProvince"
                    input-id="province-type"
                    :clearable="false"
                    class="select-size-lg"
                    label="label"
                    push-tags
                    taggable
                  /> -->
                  <b-form-input
                    id="Province"
                    v-model="addressDetails.province.label"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="จังหวัด"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Address Type -->

            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="รหัสไปรษณีย์"
                rules="integer|required|between:1,99999"
              >
                <b-form-group label="รหัสไปรษณีย์" label-for="mobile-number">
                  <b-form-input
                    id="zibcode"
                    v-model="addressDetails.zipcode"
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="รหัสไปรษณีย์"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card-body>
        <hr />
        <b-card-header class="flex-column align-items-start">
          <b-card-title
            >เลือกวิธีการชำระเงิน
            <label style="color: red;">
              *กรุณาเลือกวิธีการชำระเงิน</label
            ></b-card-title
          >
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="6" v-for="product in paymentType" :key="product.id">
              <b-card
                class="ecommerce-card card-img-packing"
                :class="paymentSelected == product.id ? 'select-card' : ''"
                no-body
                style="
                  height: 7rem;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                "
                @click="selectCard(product.id)"
              >
                <feather-icon
                  :icon="product.icon"
                  class="mb-50"
                  style="width: 2rem; height: 2rem;"
                />
                <h5>{{ product.text }}</h5>
              </b-card>
            </b-col>
            <b-col>
              <h4
                class="invoice-title mb-2"
                style="display: flex; justify-content: center;"
              >
                สรุปรายการลงออเดอร์
              </h4>
              <!-- product -->
              <div v-for="(item, index) in data" :keys="index">
                <h4 class="mt-3">
                  {{ item.productName }}
                </h4>
                <ul class="list-unstyled">
                  <li
                    class="price-detail py-50"
                    style="display: flex; justify-content: space-between;"
                  >
                    <div class="detail-title detail-total">ราคาต่อชิ้น</div>
                    <div class="detail-amt font-weight-bolder">
                      {{
                        item.checkbox
                          ? item.productMinPrice &&
                            item.productMinPrice.toLocaleString("en-US")
                          : item.productPrice &&
                            item.productPrice.toLocaleString("en-US")
                      }}
                      บาท
                    </div>
                  </li>
                  <li
                    class="price-detail py-50"
                    style="display: flex; justify-content: space-between;"
                  >
                    <div class="detail-title detail-total">จำนวน</div>
                    <div class="detail-amt font-weight-bolder">
                      {{ item.qty && item.qty.toLocaleString("en-US") }} ชิ้น
                    </div>
                  </li>
                  <hr class="pt-0" />
                  <li
                    class="price-detail"
                    style="display: flex; justify-content: space-between;"
                  >
                    <div class="detail-title detail-total">ราคารวม</div>
                    <div
                      class="detail-amt font-weight-bolder"
                      style="color: #0085ff;"
                    >
                      {{
                        item.checkbox
                          ? (
                              Number(item.productMinPrice) * Number(item.qty)
                            ).toLocaleString("en-US")
                          : (
                              Number(item.productPrice) * Number(item.qty)
                            ).toLocaleString("en-US")
                      }}
                      บาท
                    </div>
                  </li>
                </ul>
              </div>

              <hr />
              <!-- pricesell -->
              <validation-provider
                #default="validationContext"
                name="priceSell"
                rules="required|integer"
              >
                <b-form-group
                  label="ราคาขายจริง"
                  label-for="priceSell"
                  label-cols="4"
                >
                  <b-form-input
                    id="priceSell"
                    v-model="addressDetails.priceSell"
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <div>
                <ul class="list-unstyled">
                  <li
                    class="price-detail"
                    style="display: flex; justify-content: space-between;"
                  >
                    <div class="detail-title detail-total"></div>
                    <div
                      class="detail-amt font-weight-bolder"
                      style="color: red;"
                    >
                      ราคารวมที่ขายได้ คือ
                      {{ amount.toLocaleString("en-US") }} บาท
                    </div>
                  </li>
                  <hr class="pt-0" />
                </ul>
              </div>
              <!-- ยอดเปิดบิล  -->

              <h4 class="invoice-title mt-3">ยอดเปิดบิลทั้งหมด</h4>
              <div>
                <ul class="list-unstyled">
                  <li
                    class="price-detail py-50"
                    style="display: flex; justify-content: space-between;"
                  >
                    <div class="detail-title detail-total">ราคาปกติ</div>
                    <div class="detail-amt font-weight-bolder">
                      {{ amount.toLocaleString("en-US") }} บาท
                    </div>
                  </li>
                  <li
                    class="price-detail py-50"
                    style="display: flex; justify-content: space-between;"
                  >
                    <div class="detail-title detail-total">ส่วนต่าง</div>
                    <div
                      class="detail-amt font-weight-bolder"
                      style="color: red;"
                    >
                      {{
                        (
                          Number(addressDetails.priceSell) - Number(amount)
                        ).toLocaleString("en-US")
                      }}
                    </div>
                  </li>
                  <hr class="pt-0" />
                  <li
                    class="price-detail"
                    style="display: flex; justify-content: space-between;"
                  >
                    <div class="detail-title detail-total">ราคารวมทั้งหมด</div>
                    <div
                      class="detail-amt font-weight-bolder"
                      style="color: red; font-size: 1.25rem;"
                    >
                      {{
                        Number(addressDetails.priceSell).toLocaleString("en-US")
                      }}
                      บาท
                    </div>
                  </li>
                </ul>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-row>
        <b-col cols="12" style="display: flex; justify-content: center;">
          <b-button
            variant="primary"
            @click="handleSubmit(onSubmit)"
            :disabled="paymentSelected == '' || cartCount == 0"
          >
            ยืนยันออเดอร์
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, watch } from "@vue/composition-api";
import store from "@/store";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  components: {
    // BSV
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // 3rd party
    vSelect,
    VueAutosuggest,
  },
  props: {
    addressDetails: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    isWork: {
      type: Boolean,
      required: true,
    },
    cartCount :{
      type: Number
    },

    checkProductPriceType: { type: Boolean },
  },
  setup(_, { emit }) {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {});

    const onSubmit = () => {
      emit("next-step");
    };

    const paymentType = ref([
      { id: 1, text: "โอนเงิน", icon: "BriefcaseIcon" },
      { id: 2, text: "เก็บเงินปลายทาง", icon: "CpuIcon" },
    ]);
    const paymentSelected = ref(0);
    const searchContract = ref("");
    const optionsContract = ref([{ value: "", label: "" }]);

    const fetchContacts = () => {
      store
        .dispatch("confirmStore/fetchContact", {
          searchQuery: searchContract.value,
        })
        .then((response) => {
          if (response !== null) {
            response.data.data.forEach((data) => {
              optionsContract.value.push({
                value: data.contactId,
                label: data.fullname,
                contactCode: data.contactCode,
                username: data.username,
                password: data.password,
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,
                mobile: data.mobile,
                contactType: data.contactType,
              });
            });
          }
        });
    };
    fetchContacts();

    // watch(
    //   [
    //     searchContract,
    //   ],
    //   () => {
    //     fetchContacts();
    //   }
    // );

    const searchContactAddress = ref("");
    const optionsContactAddress = ref([{ value: "", label: "" }]);
    const optionsFullname = ref([{ value: "", label: "" }]);

    const fetchContactAddress = () => {
      store
        .dispatch("confirmStore/fetchContactAddress", {
          searchQuery: searchContactAddress.value,
        })
        .then((response) => {
          if (response !== null) {
            //optionsContactAddress.value = [{ value: "", label: "" }];
            /*
            response.data.data.forEach((data) => {
              optionsContactAddress.value.push({
                value: data.contactAddressId,
                label: data.fullname,
                mobile: data.mobile,
                password: data.password,
                detail: data.detail,
                detail1: data.detail1,
                province: data.province,
                district: data.district,
                subDistrict: data.subDistrict,
                default: data.default,
                zipcode: data.zipcode,
                remark: data.remark,
              });
            });
            */
          }
        });
    };
    fetchContactAddress();

    const selectedRole = ref({});
    const optionsSubdistrict = ref([]);
    const optionsDistrict = ref([]);
    const optionsProvince = ref([]);
    return {
      // Form
      onSubmit,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      // FOrm Validators
      required,
      integer,
      paymentType,
      paymentSelected,
      optionsContract,
      searchContract,
      optionsContactAddress,
      optionsFullname,
      searchContactAddress,
      optionsSubdistrict,
      optionsDistrict,
      optionsProvince,
    };
  },
  data() {
    return {
      txtContactMobile: "",
      filteredOptions: [],
      datasuggest: [],
      query: null,
    };
  },
  directives: {
    "limit-input-length": {
      inserted: (el, binding) => {
        el.addEventListener("input", (event) => {
          console.log(event.target.value.length);
          if (event.target.value.length > binding.value) {
            event.target.value = event.target.value.slice(0, binding.value);
            event.target.dispatchEvent(new Event("input"));
          }
        });
      },
    },
  },
  created() {
    this.$http.get("/autosuggest/data").then((res) => {
      this.datasuggest = res;
    });
  },
  mounted() {
    setTimeout(() => {
      //console.log('selectedContact', this.addressDetails.selectedContact);
      //console.log('selectedContactAddress', this.addressDetails.selectedContactAddress);

      if (this.addressDetails.selectedContact !== null) {
        this.addressDetails.selectedContact = null;
      }

      if (this.addressDetails.selectedContactAddress !== null) {
        this.addressDetails.selectedContactAddress = null;
      }
    }, 2000);
  },

  methods: {
    getSuggestionValue(suggestion) {
      console.log("getsuggestion", suggestion);
      const { name, item } = suggestion;
      this.addressDetails.zipcode = item.zipcode;
      // this.addressDetails.subDistrict = item.subDistrict;
      this.addressDetails.subDistrict = {
        value: 0,
        label: item.subDistrict,
      };

      this.addressDetails.district = {
        value: 0,
        label: item.district,
      };
      this.addressDetails.province = {
        value: item.provinceId,
        label: item.province,
      };
      console.log("this.addressDetails", this.addressDetails);
      return name === "hotels" ? item.title : item.name;
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }
      let fetchAddress = [];

      store
        .dispatch("confirmStore/fetchAddressByKey", {
          key: text,
        })
        .then((response) => {
          if (response !== null) {
            response.data.data.forEach((data) => {
              fetchAddress.push({
                name: `${data.zipcode} ${data.province} ${data.district} ${data.subDistrict}`,
                province: data.province,
                district: data.district,
                subDistrict: data.subDistrict,
                provinceId: data.provinceId,
                zipcode: data.zipcode,
              });
            });
          }
        });

      this.filteredOptions = [
        {
          data: fetchAddress,
        },
      ];
    },
    fetchAddress() {
      store
        .dispatch("confirmStore/fetchZipcode", {
          zipcode: this.addressDetails.zipcode,
        })
        .then((response) => {
          if (response !== null) {
            let optionsSubdistrict = [];
            let optionsDistrict = [];

            response.data.data.forEach((item) => {
              optionsSubdistrict.push({
                value: item.zoneid,
                label: item.subDistrict,
              });

              if (
                optionsDistrict.findIndex((x) => x.label === item.district) ==
                -1
              ) {
                optionsDistrict.push({
                  value: item.zoneid,
                  label: item.district,
                });
              }
            });

            this.optionsSubdistrict = optionsSubdistrict;
            this.optionsDistrict = optionsDistrict;

            console.log(response.data.data[0].zoneid);

            this.addressDetails.subDistrict = {
              value: response.data.data[0].zoneid.tosr,
              label: response.data.data[0].subDistrict,
            };

            this.addressDetails.district = {
              value: response.data.data[0].zoneid,
              label: response.data.data[0].district,
            };
            this.optionsProvince = [
              {
                value: response.data.data[0].zoneid,
                label: response.data.data[0].province,
              },
            ];
            this.addressDetails.province = {
              value: response.data.data[0].zoneid,
              label: response.data.data[0].province,
            };
          }
        });
    },
    selectCard(id) {
      this.paymentSelected = id;
      this.addressDetails.paymentTypeId = id;
    },
    setContactAddress(value) {
      console.log("setContactAddress", value);

      //if (!value.value) return null;

      if (value.length == 10) {
        store
          .dispatch("confirmStore/fetchContactAddress", {
            searchQuery: value,
          })
          .then((response) => {
            console.log("response", response.data.data[0]);
            //

            //this.addressDetails.selectedContactMobile.mobile= value;

            this.addressDetails.addressDetail = response.data.data[0].detail;
            this.addressDetails.fullname = response.data.data[0].fullname;

            this.addressDetails.contactAddressId =
              response.data.data[0].contactAddressId;

            this.optionsFullname = [
              {
                value: 0,
                label: response.data.data[0].fullname,
              },
            ];
            (this.addressDetails.selectedContact = {
              value: 0,
              label: response.data.data[0].fullname,
            }),
              (this.optionsContactAddress = [
                {
                  value: 0,
                  label: response.data.data[0].detail,
                },
              ]);
            this.addressDetails.selectedContactAddress =
              response.data.data[0].detail;

            this.optionsSubdistrict = [
              {
                value: 0,
                label: response.data.data[0].subDistrict,
              },
            ];
            this.addressDetails.subDistrict = {
              value: response.data.data[0].subDistrict,
              label: response.data.data[0].subDistrict,
            };

            this.optionsDistrict = [
              {
                value: 0,
                label: response.data.data[0].district,
              },
            ];
            this.addressDetails.district = {
              value: response.data.data[0].district,
              label: response.data.data[0].district,
            };

            this.optionsProvince = [
              {
                value: 0,
                label: response.data.data[0].province,
              },
            ];
            this.addressDetails.province = {
              value: response.data.data[0].province,
              label: response.data.data[0].province,
            };

            this.addressDetails.zipcode = response.data.data[0].zipcode;
          });
      } else {
        //this.addressDetails.selectedContactMobile = value;
        //this.addressDetails.mobile= value;

        this.addressDetails.contactAddressId =
          "00000000-0000-0000-0000-000000000000";

        this.optionsFullname = [];
        this.addressDetails.selectedContact = null;

        this.optionsContactAddress = [];
        this.addressDetails.selectedContactAddress = null;

        this.optionsSubdistrict = [];
        this.addressDetails.subDistrict = null;

        this.optionsDistrict = [];
        this.addressDetails.district = null;

        this.optionsProvince = [];
        this.addressDetails.province = null;

        this.addressDetails.zipcode = null;
      }
    },
    setContact() {
      if (this.addressDetails.selectedContact.value) {
        // this.searchContactAddress = this.addressDetails.selectedContact.label
        this.addressDetails.selectedContactMobile = this.addressDetails.selectedContact;
        this.setContactAddress();
      }
    },
    setContactMobile() {
      if (this.addressDetails.selectedContactMobile.value) {
        this.addressDetails.selectedContact = this.addressDetails.selectedContactMobile;
        // this.searchContactAddress = this.addressDetails.selectedContact.label
        this.setContactAddress();
      }
    },
    checkMaxCharacter() {
      console.log("xxx", this.addressDetails);
      if (this.addressDetails.selectedContact.length > 10) {
        // If it exceeds, remove the last selected item to enforce the limit
        this.addressDetails.selectedContact.pop();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/base/pages/app-ecommerce.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";
.card-img-packing {
  border: 1px solid;
  box-shadow: none;
  padding: 1rem;
}
.card-img-packing:hover {
  border: 2px solid #0085ff;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  transform: translateY(-5px);
}
.select-card {
  border: 2px solid #0085ff;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}
</style>
