<template>
  <div class="list-view product-checkout mt-0">
    <!-- Products List -->
    <e-commerce-checkout-step-cart-products  @fetch-cart="handlerCart" :data="data" :checkProductPriceType="checkProductPriceType" />

    <!-- Checkout Options -->
    <div class="checkout-options">
      <app-collapse>
        <app-collapse-item title="ยอดเปิดบิลทั้งหมด">
          <ul class="list-unstyled">
            <li
              class="price-detail"
              style="display: flex; justify-content: space-between;"
            >
            <div class="detail-title" style="font-size:1.25rem">
                    สินค้าจำนวน
                  </div>
                  <div class="detail-amt" style="font-size:1.25rem">
                    {{ amountProduct.toLocaleString("en-US") }} ชิ้น
                  </div>
            </li>
            
          </ul>
        </app-collapse-item>
      </app-collapse>
      <b-card class="px-2" no-body>
        <hr />
        <ul class="list-unstyled">
          <li
            class="price-detail"
            style="display: flex; justify-content: space-between;"
          >
            <div class="detail-title detail-total">
              ราคารวมทั้งหมด
            </div>
            <div class="detail-amt font-weight-bolder" style="color: red;">
              {{amount.toLocaleString("en-US")}} บาท
            </div>
          </li>
        </ul>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import ECommerceCheckoutStepCartProducts from "./ECommerceCheckoutStepCartProducts.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // SFC
    ECommerceCheckoutStepCartProducts,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    data: {
      type: Object,
    },
    amount: {
      type: Number
    },
    amountProduct: {
      type: Number
    },
    checkProductPriceType: {type : Boolean}

  },
  methods: {
    handlerCart() {
      this.$emit('fetch-cart')
    }
  }
};
</script>
