<template>
  <div>
    <div
      id="loading-bg"
      v-if="isLoading"
      style="
        z-index: 999;
        opacity: 0.5;
        margin-top: -200px;
        margin-left: -100px;
      "
    >
      <div class="loading">
        <b-spinner
          style="width: 5rem; height: 5rem;"
          label="Large Spinner"
        ></b-spinner>
      </div>
    </div>
    <div class="checkout-items">
    <b-card>
      <div style="display: flex; justify-content: space-between;" class="mb-1">
        <div class="d-flex">
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            inline
            v-model="checkProductPriceType"
            @change="ChangeProductPriceType()"
          >
          </b-form-checkbox>
          <div class="mr-50">
            {{ checkProductPriceType ? "ราคาส่ง" : "ราคาปลีก" }}
          </div>
        </div>

        <b-button variant="primary" @click="handlerOpen">
          เพิ่มสินค้า
        </b-button>
      </div>
    </b-card>
    </div>
    <e-commerce-checkout-step-cart
      :data="dataPass"
      :amount="amount"
      :amountProduct="amountProduct"
      :checkProductPriceType="checkProductPriceType"
      @fetch-cart="handlerCart"
    />
    <e-commerce-checkout-step-address
      :address-details="checkoutDetails.address"
      @next-step="handlerComfirm"
      :data="dataPass.selectedProducts"
      :amount="amount"
      :isWork="isWork"
      :checkProductPriceType="checkProductPriceType"
      :cartCount="cartCount"
     
    />
    <b-modal
      ref="confirmbill-modal"
      centered
      title=""
      hide-footer
      ok-only
      ok-title="Accept"
    >
      <div
        style="
          height: 30rem;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <b-row>
          <b-col
            cols="12"
            style="display: flex; justify-content: center;"
            class="mb-2"
          >
            <feather-icon
              icon="CheckCircleIcon"
              size="50"
              style="color: green;"
            />
          </b-col>
          <b-col
            cols="12"
            style="display: flex; justify-content: center;"
            class="mb-2"
          >
            <h2>ลงออเดอร์เรียบร้อยเเล้ว</h2>
          </b-col>
          <b-col cols="12" style="display: flex; justify-content: center;">
            <b-button
              variant="primary"
              @click="handlerToHis"
              style="font-size: 1.25rem;"
            >
              OK
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import ECommerceCheckoutStepCart from "./ECommerceCheckoutStepCart.vue";
import ECommerceCheckoutStepAddress from "./ECommerceCheckoutStepAddress.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import confirmStore from "./openbillStoreModule";
import store from "@/store";
import { getUserData } from "@/auth/utils";
import {
  BButton,
  BCard,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BModal,
  BFormCheckbox,
  VBModal,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios, { baseURL } from "@axios";
export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,
    ToastificationContent,
    // SFC
    ECommerceCheckoutStepCart,
    ECommerceCheckoutStepAddress,
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BModal,
    VBModal,
    BFormCheckbox,
    BRow,
    BCol,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      isWork: false,
      checkProductPriceType: false,
      cartCount: 0
    };
  },
  setup() {
    if (!store.hasModule("confirmStore"))
      store.registerModule("confirmStore", confirmStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule("confirmStore"))
        store.unregisterModule("confirmStore");
    });

    const refFormWizard = ref(null);
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab();
    };

    // ? This is just dummy details
    const checkoutDetails = ref({
      address: {
        fullName: "",
        mobile: "",
        // houseNo: "",
        // landmark: "",
        // city: "",
        // pincode: "",
        // state: "",
        // addressType: null,
        address: "",
        addressId: "",
        zipcode: "",
        province: "",
        provinceId: 0,
        district: "",
        districtId: 0,
        subDistrict: "",
        subDistrictId: 0,
        paymentTypeId: 0,
        priceSell: null,
        selectedContact: {},
        selectedContactMobile: {},
        selectedContactAddress: {},
      },
      payment: {
        cvv: "",
      },
    });
    const isLoading = ref(false);
    const productsAll = ref([]);

    const productOptions = [];

    const fetchProducts = () => {
      store.dispatch("confirmStore/fetchSubinventorys").then((response) => {
        const userData = getUserData();
        response.data.data.forEach((item) => {
          if (userData.role == "ตัวแทนขาย") {
            console.log("item.userId", item.userId);

            if ((item.sumStockPartner || 0) - (item.realSale || 0) > 0) {
              let productdata = item;
              productdata.qty = 1;
              productdata.checkbox = false;
              productsAll.value.push({
                ...productdata,
              });
              productOptions.push({
                ...productdata,
              });
            }
          } else if (userData.role == "พนักงานขาย") {
            console.log("item.saleRoleId", item.saleRoleId);
            if (
              item.saleRoleId != "00000000-0000-0000-0000-000000000000" &&
              item.saleRoleId != null
            ) {
              if (item.realBalance > 0) {
                let productdata = item;
                productdata.qty = 1;
                productdata.checkbox = false;
                productsAll.value.push({
                  ...productdata,
                });
                productOptions.push({
                  ...productdata,
                });
              }
            }
          } else {
            console.log("item.teamRoleId", item.teamRoleId);
            if (
              item.teamRoleId != "00000000-0000-0000-0000-000000000000" &&
              item.teamRoleId != null
            ) {
              if (item.realBalance > 0) {
                let productdata = item;
                productdata.qty = 1;
                productdata.checkbox = false;
                productsAll.value.push({
                  ...productdata,
                });
                productOptions.push({
                  ...productdata,
                });
              }
            }
          }
        });
      });
    };
    fetchProducts();
    const amount = ref(0);
    const amountProduct = ref(0);
    const dataPass = ref({
      selectedProducts: [],
      products: productsAll,
    });

    const invenId = ref("");
    store.dispatch("confirmStore/fetchInventory").then((response) => {
      invenId.value = response.data.data[0].inventoryId;
    });

    const statusOptions = [{ value: null, label: "ทั้งหมด" }];

    const fetchCart = () => {
      const userData = getUserData();
      store
        .dispatch("confirmStore/fetchCartPro", { id: userData.userId })
        .then((response) => {
          console.log("responsedffs", response);
          // dataPass.value.selectedProducts = response.data.cartModels;
          console.log("dataPass.value", dataPass.value.selectedProducts);
        });
    };
    fetchCart();

    return {
      refFormWizard,
      formWizardNextStep,

      // Dummy Details
      checkoutDetails,
      dataPass,
      amount,
      amountProduct,
      invenId,
      productOptions,

      statusOptions,
      isLoading,
      fetchCart,
    };
  },

  // watch: {
  //   dataPass: {
  //     handler(newValue, oldValue) {
  //       this.amount = 0;
  //       this.amountProduct = 0;
  //       this.dataPass.selectedProducts.forEach((item) => {
  //         console.log(item);
  //         if (this.checkProductPriceType) {
  //           this.amount += item.productMinPrice * item.qty;
  //           this.amountProduct += Number(item.qty);
  //         } else {
  //           this.amount += item.productPrice * item.qty;
  //           this.amountProduct += Number(item.qty);
  //         }
  //       });
  //     },
  //     deep: true,
  //   },
  // },
  mounted() {
    // this.fetchAmount();
    this.handlerCart();
  },
  methods: {
    handlerCart(product) {
      console.log('pass')
      const userData = getUserData();

      store
        .dispatch("confirmStore/fetchCartPro", { id: userData.userId })
        .then((response) => {
          console.log("responsedffs", response);
          this.dataPass.selectedProducts = response.data.cartModels;
          this.cartCount = this.dataPass.selectedProducts == null ? 0 : 1
          console.log("dataPass.value", this.dataPass.selectedProducts);
           this.fetchAmount();
        });
    },
    fetchAmount() {
      this.amount = 0;
      this.amountProduct = 0;
      this.dataPass.selectedProducts.forEach((item) => {
        console.log(item);
        if (this.checkProductPriceType) {
          this.amount += item.productMinPrice * item.qty;
          this.amountProduct += Number(item.qty);
        } else {
          this.amount += item.productPrice * item.qty;
          this.amountProduct += Number(item.qty);
        }
      });
    },
    showToast(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon,
          text,
          variant,
        },
      });
    },

    handlerComfirm() {
      const userData = getUserData();
      var productList = [];

      if (
        this.dataPass.selectedProducts != null &&
        this.checkoutDetails.address.mobile.length == 10 &&
        this.isLoading != true
      ) {
        this.isLoading = true;

        const saveModel = {
          orderType: "salesorder",
          contactAddressId: "00000000-0000-0000-0000-000000000000",
          userId: userData.userId,
          // orderLists: productList,
          totalPrice: this.checkoutDetails.address.priceSell,
          // totalPriceStd: this.amount,
          orderStatus: 1,
          orderPayment: this.checkoutDetails.address.paymentTypeId,
          OrderCategoryType: this.checkProductPriceType
            ? "wholesale"
            : "retail",
          orderAddressDetail: this.checkoutDetails.address.addressDetail,
          orderAddressMobile: this.checkoutDetails.address.mobile,
          orderAddressFullname: this.checkoutDetails.address.fullname,
          orderAddressProvince: this.checkoutDetails.address.province
            ? this.checkoutDetails.address.province.label
            : "",
          orderAddressDistrict: this.checkoutDetails.address.district
            ? this.checkoutDetails.address.district.label
            : "",
          orderAddressSubDistrict: this.checkoutDetails.address.subDistrict
            ? this.checkoutDetails.address.subDistrict.label
            : "",
          orderAddressZipcode: this.checkoutDetails.address.zipcode.toString(),
        };

        var payload = saveModel;

        store
          .dispatch("confirmStore/addOrder", payload)
          .then((res) => {
            this.isWork = false;

            if (res.status == 200) {
              this.isLoading = false;
              //this.$refs["confirmbill-modal"].show();
              this.showToast("success", "XCircleIcon", "ลงออเดอร์สำเร็จ");

              // this.$router.push({ name: "history-order" });
              this.$router.push({
                name: "history-orderpreview",
                params: { id: res.data },
              });
            } else {
              this.showToast("error", "XCircleIcon", "ไม่มีสินค้าในตะกร้า");
              this.handlerCart();
            this.isLoading = false;
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.showToast("error", "XCircleIcon", "ไม่มีสินค้าในตะกร้า");
              this.handlerCart();
            this.isLoading = false;
            console.log("error > ", error);
            //this.showToast("danger", "XCircleIcon", error);
          });

        /*

              store
                .dispatch("confirmStore/handlerOpenbill", productAddList)
                .then((res) => {
                  console.log("res", res);
                  if (res.status == 200) {
                    store
                      .dispatch("confirmStore/handlerConfirmNew", saveModel)
                      .then((res) => {
                        this.isWork = false;
                       
                        if (res.status == 200) {
                          this.isLoading = false;
                          //this.$refs["confirmbill-modal"].show();
                          this.showToast("success", "XCircleIcon", "ลงออเดอร์สำเร็จ");
                          this.$router.push({ name: "history-order" });
                         
                        }
                      })
                      .catch((error) => {
                        this.isLoading = false;
                        this.showToast("danger", "XCircleIcon", error);
                      });
                  }
                })
                .catch((error) => {
                  this.showToast("danger", "XCircleIcon", error);
                });
        
      
      */
      } else {
        this.showToast("danger", "XCircleIcon", "เบอร์โทรศัพท์ไม่ถูกต้อง");
      }
    },
    handlerToHis() {
      this.$refs["confirmbill-modal"].hide();
    },
    handlerOpen(product) {
      this.$root.$refs.CartProduct.resetValue();
    },
    ChangeProductPriceType(){
      console.log("ChangeProductPriceType");
      const userData = getUserData();

      var data = {
        userId : userData.userId
      }

      axios
      .post(`${baseURL}/api/Cart/DeleteCartsByUserId?userId=${userData.userId}`, data)
      .then((response) => {
            console.log(response);
            this.handlerCart()
          })
          .catch((error) => {
            console.log(error);
          });

    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
