<template>
  <div class="checkout-items">
    <b-card
      v-for="(product, idx) in data.selectedProducts"
      :key="product.productCode"
      class="ecommerce-card"
      no-body
      style="display: flex;"
    >
      <b-row class="px-50">
        <!--  <b-col cols="1" style="display: flex; align-items: center">
          <b-form-checkbox v-model="data.selectedProducts" :value="product">
          </b-form-checkbox>
        </b-col>-->
        <b-col cols="5">
          <!-- Product Image -->
          <!-- <div class="item-img">
           
              <b-img
                :src="product.productId.product_Image_Id.url || require('@/assets/images/pages/eCommerce/3.png')"    
                style="width: 8rem;height: 8rem;"
              />
         
          </div> -->
          <b-card-body>
            <div class="item-name">
              <h4 class="mb-50">
                {{ product.productCode }}
              </h4>
              <!-- <p
                class="item-company"
                style="font-size: 1.25rem;"
                v-if="userData.role !== 'แม่ทีม'"
              >
                สินค้าคงเหลือ
                <span style="color: red; padding-left: 5px;">
                  &nbsp;
                  {{
                    userData.role == "ตัวแทนขาย"
                      ? (
                          (product.sumStockPartner || 0) -
                          (product.realSale || 0)
                        ).toLocaleString("en-US")
                      : product.realBalance.toLocaleString("en-US")
                  }}</span
                >
              </p> -->
            </div>
          </b-card-body>
        </b-col>
        <b-col cols="6">
          <!-- Product Details: Card Body -->
          <b-card-body>
            <!-- <div class="item-name">
              <h6 class="mb-0">
                <b-link class="text-body">
                  {{ product.productId.productName }}
                </b-link>
              </h6>
              <span class="item-company" style="font-size: 1.25rem"
                >ราคา
                <b-link class="company-name">{{ product.productId.price.toLocaleString("en-US") }}</b-link></span
              >
            </div> -->

            <span class="item-company" style="font-size: 1.25rem;"
              >ราคา
              <b-link class="company-name">{{
                checkProductPriceType
                  ? product.productMinPrice &&
                    product.productMinPrice.toLocaleString("en-US")
                  : product.productPrice &&
                    product.productPrice.toLocaleString("en-US")
              }}</b-link></span
            >
            <div class="item-quantity">
              <span class="quantity-title">Qty:</span>

              <span class="ml-1" style="font-size: 1.25rem;">{{
                product.qty
              }}</span>
            </div>
            <div class="mt-1">
              <b-button
                variant="warning"
                size="sm"
                @click="handlerEdit(product)"
              >
                แก้ไขสินค้า
              </b-button>
              <feather-icon
                class="ml-1"
                icon="TrashIcon"
                @click="handlerDelete(product)"
              />
            </div>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      ref="selectProduct"
      id="selectProduct"
      centered
      title=""
      hide-footer
      ok-only
      ok-title="Accept"
      :no-close-on-backdrop="true"
    >
      <div
        style="
          height: 30rem;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <b-row>
          <b-col
            cols="12"
            style="display: flex; justify-content: center;"
            class="mb-1"
          >
            <h4>{{ isEdit ? "แก้ไขสินค้า" : "เพิ่มสินค้า" }}</h4>
          </b-col>
          <div
            class="d-flex mb-1"
            style="justify-content: center; width: 100%;"
          >
            <v-select
              v-model="selectProducts"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="productCode"
              :options="data.products"
              placeholder="กรุณาเลือกสินค้า"
              class="w-75"
              :disabled="isEdit"
              :searchable="false"
            />
          </div>
          <div
            class="d-flex mb-1"
            style="justify-content: center; width: 100%;"
          >
            <div class="item-quantity d-flex" v-if="selectProducts">
              <span class="item-company" style="font-size: 1.25rem;"
                >ราคา
                <b-link class="company-name">
                  {{
                    checkProductPriceType
                      ? selectProducts.productMinPrice.toLocaleString("en-US")
                      : selectProducts.productPrice.toLocaleString("en-US")
                  }}
                </b-link></span
              >
            </div>
          </div>
          <div
            class="d-flex mb-1"
            style="justify-content: center; width: 100%;"
          >
            <div class="item-quantity d-flex" v-if="selectProducts && !isEdit">
              <p
                class="item-company"
                style="font-size: 1.25rem;"
                v-if="userData.role !== 'แม่ทีม'"
              >
                สินค้าคงเหลือ
                <span style="color: red; padding-left: 5px;">
                  &nbsp;
                  {{
                    userData.role == "ตัวแทนขาย"
                      ? (
                          (selectProducts.sumStockPartner || 0) -
                          (selectProducts.realSale || 0)
                        ).toLocaleString("en-US")
                      : selectProducts.realBalance.toLocaleString("en-US")
                  }}</span
                >
              </p>
            </div>
          </div>
          <div
            class="d-flex mb-1"
            style="justify-content: center; width: 100%;"
          >
            <div class="item-quantity d-flex">
              <span class="quantity-title">Qty:</span>
              <b-form-input
                v-model="qtyProduct"
                size="sm"
                class="ml-75"
                inline
                type="number"
              />
            </div>
          </div>

          <b-col cols="12" style="display: flex; justify-content: center;">
            <b-button
              variant="primary"
              style="font-size: 1.25rem;"
              @click="handlerConfirm(selectProducts)"
              :disabled="qtyProduct < 1 || !selectProducts"
            >
              OK
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BButton,
  BBadge,
  BFormSpinbutton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormInput,
} from "bootstrap-vue";
import store from "@/store";
import { ref } from "@vue/composition-api";
import { formatDate } from "@core/utils/filter";
import { useEcommerce, useEcommerceUi } from "../useEcommerce";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormSpinbutton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      selected: ["B", "C"],
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  props: {
    data: {
      type: Object,
    },
    checkProductPriceType: { type: Boolean },
  },
  setup() {
    const products = ref([]);

    const { toggleProductInWishlist } = useEcommerceUi();
    const { removeProductFromCart } = useEcommerce();
    const removeProductFromCartClick = (product) => {
      removeProductFromCart(product.id).then(() => {
        const productIndex = products.value.findIndex(
          (p) => p.id === product.id
        );
        products.value.splice(productIndex, 1);

        store.commit(
          "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
          products.value.length
        );
      });
    };

    const fetchCartProducts = () => {
      store.dispatch("app-ecommerce/fetchCartProducts").then((response) => {
        products.value = response.data.products.filter((item) => item.qty > 0);
      });
    };
    fetchCartProducts();
    const productOptions = [];
    const selectProducts = ref(null);
    const qtyProduct = ref(1);
    const isEdit = ref(false);
    return {
      products,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,

      // Filter
      formatDate,
      productOptions,
      selectProducts,
      qtyProduct,
      isEdit,
    };
  },
  mounted() {
    console.log("this", this.data.products);
    setTimeout(() => {
      this.productOptions = this.data.products;
    }, 2000);
  },
  created() {
    this.$root.$refs.CartProduct = this;
  },
  //checkQty
  methods: {
    showToast(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon,
          text,
          variant,
        },
      });
    },
    checkQty(idx) {
      setTimeout(() => {
        var realBalance =
          this.userData.role == "ตัวแทนขาย"
            ? parseInt(this.data.products[idx].sumStockPartner || 0) -
              parseInt(this.data.products[idx].realSale || 0)
            : parseInt(this.data.products[idx].realBalance);

        console.log("Qty", parseInt(this.data.products[idx].qty));
        console.log("realBalance", realBalance);

        if (parseInt(this.data.products[idx].qty) > realBalance) {
          this.data.products[idx].qty = realBalance;
        }
      }, 100);
    },
    handlerEdit(product) {
      this.selectProducts = product;
      this.qtyProduct = product.qty;
      this.isEdit = true;
      this.$refs["selectProduct"].show();
    },
    handlerConfirm(product) {
      const userData = getUserData();
      if (this.isEdit) {
        let saveModel = {
          cartId: "00000000-0000-0000-0000-000000000000",
          productId: product.productId,
          contactId: "00000000-0000-0000-0000-000000000000",
          userId: userData.userId,
          qty: this.qtyProduct,
          price: this.checkProductPriceType
            ? product.productMinPrice
            : product.productPrice,
          minPrice: product.productMinPrice,
          maxPrice: 0,
          totalPrice:
            this.qtyProduct *
            (this.checkProductPriceType
              ? product.productMinPrice
              : product.productPrice),
        };

        store
          .dispatch("confirmStore/updateCartProduct", saveModel)
          .then((response) => {
            if (response.status === 200) {
              this.showToast(
                "success",
                "CheckIcon",
                "Your Work has been saved"
              );
              this.$emit("fetch-cart");
              this.$refs["selectProduct"].hide();
            }
          })
          .catch((error) => {
            this.showToast("danger", "XCircleIcon", "error");
          });
      } else {
        let saveModel = {
          cartId: "00000000-0000-0000-0000-000000000000",
          productId: product.productId,
          contactId: "00000000-0000-0000-0000-000000000000",
          userId: userData.userId,
          qty: this.qtyProduct,
          price: this.checkProductPriceType
            ? product.productMinPrice
            : product.productPrice,
          minPrice: product.productMinPrice,
          maxPrice: 0,
          totalPrice:
            this.qtyProduct *
            (this.checkProductPriceType
              ? product.productMinPrice
              : product.productPrice),
        };
        store
          .dispatch("confirmStore/addCartProduct", saveModel)
          .then((response) => {
            if (response.status === 200) {
              this.showToast(
                "success",
                "CheckIcon",
                "Your Work has been saved"
              );
              this.$emit("fetch-cart");
              this.$refs["selectProduct"].hide();
            }
          })
          .catch((error) => {
            this.showToast("danger", "XCircleIcon", "error/duplicate");
          });
      }
    },
    handlerDelete(product) {
      const userData = getUserData();

      this.$swal({
        title: "ต้องการลบสินค้าออกจากตะกร้า",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let saveModel = {
            cartId: "00000000-0000-0000-0000-000000000000",
            productId: product.productId,
            contactId: "00000000-0000-0000-0000-000000000000",
            userId: userData.userId,
            qty: product.qty,
            price: this.checkProductPriceType
              ? product.productMinPrice
              : product.productPrice,
            minPrice: product.productMinPrice,
            maxPrice: 0,
            totalPrice:
              this.qtyProduct *
              (this.checkProductPriceType
                ? product.productMinPrice
                : product.productPrice),
          };

          store
            .dispatch("confirmStore/deleteCartProduct", saveModel)
            .then((response) => {
              if (response.status === 200) {
                this.showToast(
                  "success",
                  "CheckIcon",
                  "Your Work has been delete"
                );
                this.$emit("fetch-cart");
              }
            })
            .catch((error) => {
              this.showToast("danger", "XCircleIcon", "error");
            });
        }
      });
    },
    resetValue() {
      this.selectProducts = null;
      this.qtyProduct = 1;
      this.isEdit = false;
      this.$refs["selectProduct"].show();
    },
  },
};
</script>

<style></style>
